import React, { useState, useEffect } from "react";
import styled from "styled-components";
import projection from "../assets/loop2.mp4";
import QRCode from "qrcode";

const HeroBg = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;
const VideoBg = styled.video`
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	/* opacity: 1; */
	background: #000;
	/* z-index: 999; */
`;

const QrImage = styled.img`
	width: 100px;
	height: 100px;
	margin-right: 5px;
	border-radius: 10px;
`;

const ArtistInfo = styled.div`
	display: flex;
	margin: 10px;
	position: absolute;
	bottom: 20px;
	right: 240px;
	align-items: center;
	justify-content: center;
	/* border: 2px solid #fff; */
	/* background: #fff; */
	padding: 0 10px;
	border-radius: 20px;
	h4 {
		font-size: 1rem;
		weight: 800;
		color: #f4f4f4;
		font-family: "Inter", sans-serif;
		text-align: left;

		span {
			color: pink;
			font-size: 0.8rem;
		}
	}
`;

const Display = () => {
	const [purchaseQr, setPurchaseQr] = useState(null);
	useEffect(() => {
		QRCode.toDataURL("https://instagram.com/superkid.eth").then(setPurchaseQr);
	}, []);

	return (
		<>
			<HeroBg>
				{/* <ArtistInfo> */}
				{/* <QrImage src={purchaseQr} alt="qr-code" /> */}
				{/* <h4>
						Steve Zafeiriou
						<br />
						<span>@superkid.eth</span>
					</h4> */}
				{/* </ArtistInfo> */}
				{/* <QrImage src={purchaseQr} alt="qr-code" /> */}
				<VideoBg src={projection} type="video/mp4" autoPlay loop muted />
			</HeroBg>
		</>
	);
};

export default Display;
