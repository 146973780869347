import React from "react";
import Display from "./components/Display";

function App() {
	return (
		<>
			<Display />
		</>
	);
}

export default App;
